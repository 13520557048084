<template>
  <div
    :class="{
      'd-flex align-center h-100': !mobile,
      'mobile-menu-content': mobile
    }"
  >
    <div class="mr-10">
      <a href="https://www.dvisionlab.com" target="_blank">
        <v-img
          src="/media/images/dvision_logo_2019_symbol.png"
          width="70"
          max-width="70"
          contain
          class="toolbar-logo-brand"
        >
        </v-img>
      </a>
    </div>

    <menu-device :dark="dark" :mobile="mobile" />
    <menu-chart :dark="dark" :mobile="mobile" />
  </div>
</template>

<script>
import MenuDevice from "./MenuDevice";
import MenuChart from "./MenuChart";

export default {
  props: {
    dark: { default: false, type: Boolean },
    mobile: { default: false, type: Boolean }
  },
  components: { MenuDevice, MenuChart },
  computed: {}
};
</script>

<style lang="scss">
.header-group {
  height: calc(100% - 0.5rem) !important;
  border: 1px solid rgba(255, 255, 255, 0.12);

  .header-title {
    height: 1rem;
    overflow: hidden;
    text-transform: uppercase;
    text-align: center;

    // text-caption
    font-size: 0.65rem;
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: 0.0333333333em;

    & ~ .v-btn-toggle,
    & ~ .v-btn {
      height: calc(100% - 1rem - 1px);
      vertical-align: bottom;
    }

    & ~ .v-btn-toggle > .v-btn {
      height: 100% !important;
      vertical-align: bottom;
    }
  }

  .v-btn-toggle,
  .v-btn {
    height: 100%;

    .v-btn:before {
      background-color: transparent;
    }
  }
}
</style>
