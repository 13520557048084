import Vue from "vue";
import VueRouter from "vue-router";
import goTo from "vuetify/es5/services/goto";

Vue.use(VueRouter);

// Dynamic components
// TODO use this: https://vuejs.org/v2/guide/components-dynamic-async.html#Handling-Loading-State
const Home = () => import("@/views/Home");
const Dashboard = () => import("@/views/Dashboard");
const Dashstats = () => import("@/views/Dashstats");
const DashAR = () => import("@/views/DashAR");

const routes = [
  // redirect all not matching routes to "/"
  {
    path: "*",
    redirect: "/"
  },
  {
    path: "/",
    name: "home",
    meta: { auth: false }, // for ditto login
    component: Home
  },
  {
    path: "/dashboard",
    name: "dashboard",
    meta: { auth: false }, // for ditto login
    component: Dashboard
  },
  {
    path: "/dashstats",
    name: "dashstats",
    meta: { auth: false }, // for ditto login
    component: Dashstats
  },
  {
    path: "/ar/:id",
    name: "ar",
    meta: { auth: false }, // for ditto login
    component: DashAR
  }
];

const router = new VueRouter({
  mode: "history",
  base: __dirname,
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo);
  }
});

export default router;
