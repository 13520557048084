import Vue from "vue";
import store from "@/store/index";

import { map } from "lodash";

/**
 * GET all configurations
 * @returns {Promise} - Return a promise which will resolve when server replies
 */
function getAllConfigurations() {
  let promise = new Promise((resolve, reject) => {
    Vue.$http
      .get(`/api/configurations/`)
      .then(resp => {
        resolve(resp);
      })
      .catch(error => {
        reject(error);
      });
  });
  return promise;
}

export function setupConfigurations() {
  getAllConfigurations().then(configs => {
    let devices = map(configs, "device_id");
    let tags = map(configs, "tag");
    // store configurations list
    store.dispatch("setConfigurations", configs);
    // store devices list and setup device dict for live data
    store.dispatch("setDevices", devices);
    // store tags list
    store.dispatch("setTags", tags);
  });
}
