<template>
  <basic-wireframe v-bind="options">
    <!-- route dependant wireframe components: defined in router -->
    <router-view />

    <!-- named wireframe slots -->
    <template v-if="displayTopBar" v-slot:bar="{ className, dark, mobile }">
      <!-- fixed wireframe components: imported here -->
      <app-bar :class="className" :dark="dark" :mobile="mobile" />
    </template>
  </basic-wireframe>
</template>

<script>
import AppBar from "@/components/header/Bar";
import { BasicWireframe } from "@/../ditto/wireframes";
import * as ws from "@/common/api.websockets";
import { setupConfigurations } from "@/common/api.configurations";

// fonts
import "typeface-raleway";

const options = {
  bar: {
    color: "dark",
    dense: true
    // mobileMenuComponent: () => import("@/components/header/Logo")
  },
  mobileBreakpoint: "xs" // default: xs
};

// function getRandomInt(min, max) {
//   min = Math.ceil(min);
//   max = Math.floor(max);
//   return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
// }

export default {
  name: "Home",
  components: {
    AppBar,
    BasicWireframe
  },
  data: () => ({
    options
  }),
  computed: {
    displayTopBar() {
      return this.$route.name == "home" || this.$route.name == "ar"
        ? false
        : true;
    }
  },
  created() {
    setupConfigurations();
    // Connect to websocket, disconnect on page unload
    this.connectWebsocket();
    window.addEventListener("beforeunload", () => {
      ws.disconnect();
    });

    // DEV
    // setTimeout(() => {
    //   setInterval(() => {
    //     console.log("new measurement");
    //     this.$store.dispatch("appendMeasurement", [
    //       "F7AC21BB",
    //       {
    //         temperature: getRandomInt(18, 25),
    //         humidity: getRandomInt(20, 50),
    //         voc: getRandomInt(1, 5),
    //         co2: getRandomInt(400, 800),
    //         timestamp: new Date()
    //       }
    //     ]);
    //     // this.$store.dispatch("appendMeasurement", [
    //     //   "909581B4",
    //     //   {
    //     //     temperature: 20,
    //     //     humidity: getRandomInt(20, 50),
    //     //     voc: getRandomInt(1, 5),
    //     //     co2: getRandomInt(400, 800),
    //     //     timestamp: new Date()
    //     //   }
    //     // ]);
    //   }, 5000);
    // }, 2000);
  },
  methods: {
    connectWebsocket() {
      const onMessage = msg => {
        let data = JSON.parse(msg.data);
        if (data) {
          console.log(data);
          this.$store.dispatch("appendMeasurement", [
            data.device_id,
            {
              temperature: data.temperature,
              humidity: data.humidity,
              voc: data.voc,
              co2: data.co2,
              timestamp: new Date()
            }
          ]);
        }
      };
      const onClose = () => {
        console.log("closed");
      };
      const onError = error => {
        console.error(error);
      };
      ws.connect("sensors-data", onMessage, onClose, onError);
    }
  }
};
</script>

<style>
body {
  font-family: "raleway" !important;
}
.neu-glow {
  box-shadow: 12px 12px 16px 0 rgba(0, 0, 0, 0.25),
    -2px -2px 3px 0 rgba(255, 255, 255, 0.3) !important;
}
.h-100 {
  height: 100%;
}
.w-100 {
  width: 100%;
}
</style>
