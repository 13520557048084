import Vue from "vue";
import Vuex from "vuex";

// modules

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    configurations: [],
    devices: [],
    tags: [],
    activeDevice: "F7AC21BB"
  },
  mutations: {
    setConfigurations: (state, value) => (state.configurations = value),
    setDevices: (state, value) => {
      state.devices = value;
      state.devices.forEach(device_id => {
        let measurements = {
          temperature: [],
          humidity: [],
          co2: [],
          voc: [],
          timestamp: []
        };
        Vue.set(state, device_id, measurements);
      });
    },
    setTags: (state, value) => (state.tags = value),
    setActiveDevice: (state, value) => Vue.set(state, "activeDevice", value),

    appendMeasurement: (state, v) => {
      state[v.device_id]["temperature"].push(v.d.measurement["temperature"]);
      state[v.device_id]["humidity"].push(v.d.measurement["humidity"]);
      state[v.device_id]["co2"].push(v.d.measurement["co2"]);
      state[v.device_id]["voc"].push(v.d.measurement["voc"]);
      state[v.device_id]["timestamp"].push(v.d.measurement["timestamp"]);
    },
    initMeasurement: (state, v) => {
      state[v.device_id]["temperature"] = v.d.measurement["temperature"];
      state[v.device_id]["humidity"] = v.d.measurement["humidity"];
      state[v.device_id]["co2"] = v.d.measurement["co2"];
      state[v.device_id]["voc"] = v.d.measurement["voc"];
      state[v.device_id]["timestamp"] = v.d.measurement["timestamp"];
    }
  },
  actions: {
    setConfigurations: ({ commit }, value) => {
      commit("setConfigurations", value);
    },
    setDevices: ({ commit }, value) => {
      commit("setDevices", value);
    },
    setTags: ({ commit }, value) => {
      commit("setTags", value);
    },
    appendMeasurement: ({ commit }, [device_id, measurement]) => {
      commit("appendMeasurement", { device_id, d: { measurement } });
    },
    initMeasurement: ({ commit }, [device_id, measurement]) => {
      commit("initMeasurement", { device_id, d: { measurement } });
    },
    setActiveDevice: ({ commit }, value) => {
      commit("setActiveDevice", value);
    }
  },
  modules: {}
});
