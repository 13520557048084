<template>
  <div>
    <!-- mobile menu -->
    <v-list-group v-if="mobile" color="primary">
      <template v-slot:activator>
        <v-list-item-content>
          <v-list-item-title>DEVICES</v-list-item-title>
          <v-list-item-subtitle>
            <div class="d-flex align-center">
              <v-icon color="primary">mdi-check</v-icon>
              <span class="primary--text text-uppercase pl-2">
                {{
                  actions[selectedActionIndex]
                    ? actions[selectedActionIndex].name
                    : null
                }}
              </span>
            </div>
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>

      <v-list-item-group mandatory v-model="selectedActionIndex">
        <v-list-item v-for="(action, i) in actions" :key="i">
          <v-list-item-icon>
            <v-icon v-if="action.icon">{{ action.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              <span>{{ action.name }}</span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list-group>

    <!-- desktop menu -->
    <div v-else class="header-group">
      <div class="header-title">DEVICES</div>

      <v-btn-toggle
        color="primary"
        group
        height="100%"
        mandatory
        tile
        v-model="selectedActionIndex"
      >
        <template v-for="(action, index) in actions">
          <v-tooltip :key="index" bottom :nudge-bottom="-5" :open-delay="500">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ma-0 neu-glow"
                :value="index"
                v-bind="attrs"
                v-on="on"
              >
                <template v-if="action.icon">
                  <v-icon>{{ action.icon }}</v-icon>
                </template>
                <span v-else>{{ action.name }}</span>
              </v-btn>
            </template>
            <span class="text-caption text-uppercase">{{ action.name }}</span>
          </v-tooltip>
        </template>
      </v-btn-toggle>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dark: { default: false, type: Boolean },
    mobile: { default: false, type: Boolean }
  },
  data: () => ({
    actions: [
      { name: "DV", value: "F7AC21BB", icon: "mdi-numeric-1-box-outline" },
      {
        name: "Entrance",
        value: "909581B4",
        icon: "mdi-numeric-2-box-outline"
      },
      { name: "Office", value: "CD965C75", icon: "mdi-numeric-3-box-outline" },
      { name: "221e", value: "020B3F0C", icon: "mdi-numeric-4-box-outline" }
    ],
    selectedActionIndex: 0
  }),
  mounted() {
    this.$store.dispatch("setActiveDevice", this.actions[0].value);
  },
  watch: {
    selectedActionIndex(v) {
      this.$store.dispatch("setActiveDevice", this.actions[v].value);
    }
  }
};
</script>
