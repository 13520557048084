let ws;
// let connectionAttempts; TODO
let onCloseListener, onErrorListener;

const URL =
  window.location.protocol == "https:"
    ? `wss:/${window.location.host}/ws/sensors/`
    : `ws:/${window.location.host}/ws/sensors/`;

console.log(URL);

function Group(name) {
  this._name = name;
  this._callbacks = {};
}

Group.prototype.join = function (error) {
  this._callbacks.join(error);
};

Group.prototype.leave = function (error) {
  this._callbacks.leave(error);
};

Group.prototype.data = function (command, data) {
  this._callbacks.data(command, data);
};

Group.prototype.error = function (command, error) {
  this._callbacks.error(command, error);
};

Group.prototype.addListener = function (functionName, handler) {
  this._callbacks[functionName] = handler;
};

Group.prototype.removeListener = function (functionName) {
  delete this._callbacks[functionName];
};

// Export functions
// ----------------

export const connect = (group, onMessage, onClose, onError) => {
  ws = new WebSocket(URL + group + "/");

  ws.onopen = () => {
    console.log("Connected to WebSocket");
  };
  onCloseListener = event => onClose(event);
  onErrorListener = event => onError(event);

  ws.onclose = onCloseListener;
  ws.onerror = onErrorListener;

  ws.onmessage = event => onMessage(event);
};

export const disconnect = () => {
  if (ws) {
    ws.onclose = null;
    ws.onerror = null;

    ws.close();
    ws = undefined;

    console.warn("WS disconnected");
  }
};
